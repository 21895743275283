.landing-image-container {
    width: 100%;
    height: 80vh; /* Full screen height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .landing-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures it covers the container */
  }

  /* Responsive design for mobile view */
  @media (max-width: 768px) {
    .landing-image-container {
      height: 50vh;
    }
    .landing-image {
      width: 100%;
      height: 100%;
    }
  }
  