.providers-container {
    background-color: #f3ede6;
    padding: 100px 20px;
    text-align: center;

    /* Flexbox for column alignment */
    display: flex;
    flex-direction: column;  /* Stack items vertically */
    align-items: center;  /* Center items horizontally */
    justify-content: center;  /* Center items vertically */
    width: 100%;
}


.providers-container h1 {
    font-family: "Rasputin Light";
    font-size: 35px;
    text-transform: uppercase;
    text-align: center; /* Centers the text */
    max-width: 80%; /* Limits width to 80% of the container */
    margin: 0 auto; /* Centers it within its parent */
    line-height: 1.2; /* Ensures proper line spacing */
    word-break: break-word; /* Prevents words from breaking weirdly */
    color: black;
    font-weight: 200;
}

.providers-container h2 {
    line-height: 1.2; /* Ensures proper line spacing */
    color: black;
    font-weight: 200;

    font-family: "Rasputin Light";
    font-size: 30px;
    margin-bottom: 40px;
    text-align: center; /* Centers the text */
    max-width: 70%; /* Limits width to 80% of the container */
    
}

/* Grid Layout for Providers */
.providers-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 3 per row on desktop */
    gap: 30px; /* Reduced gap to avoid too much spacing */
    justify-content: center;
    max-width: 1200px; /* Limits the width of the grid */
    margin: 0 auto; /* Centers the grid */
    padding: 0 20px; /* Adds space on the sides */
    margin-top: 50px;
}

/* Provider Card */
.provider-card {
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 18px;
    text-align: left;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.provider-card:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Provider Image */
.provider-image {
    width: 100%;
    height: 320px;
    border-radius: 10px;
}

/* Provider Info Section */
.provider-info {
    margin-top: 0px;
}
/* Ensures items are in a row and take full width */
.provider-top-line {
    display: flex;
    justify-content: space-between; /* Spaces elements evenly */
    align-items: center; /* Aligns items vertically */
    width: 100%; /* Takes full width */
}


/* Name Styling */
.provider-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}

/* Service & Review */
.provider-details {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: #333;
}

/* Price Styling */
.provider-price {
    font-size: 16px;
    color: #888;
    margin-left: 10px;
}

.see-more-button {
    margin-top: 80px;
    padding: 12px 24px;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: white;
    color: black;
    border: 2px solid black;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.see-more-button:hover {
    background-color: #f0f0f0;
}

/* Responsive Design for Phones */
@media (max-width: 768px) {
    .providers-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 per row on mobile */
        gap: 20px;
        max-width: 100%; /* Removes width restrictions */
        padding: 0 0px; /* Adds space on the sides */

    }

    .provider-card {
        padding: 15px;
    }

    .provider-image {
        height: 180px;
    }

    .provider-name {
        font-size: 18px;
    }

    .provider-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 16px;
    }



    .star-icon {
        margin-top: 2px; /* Minimal gap between price and star */
        font-size: 16px; /* Adjust size if needed */
    }
    .providers-container h1 {
        font-size: 30px;
    }

    .providers-container {
        padding: 20px 20px;
    }

    .providers-container h2 {
        font-size: 20px;
    }
}
