.babysitters-container {
    background-color: #32402f;
    text-align: center;
}

/* First Row: Title + First Image */
.first-row {
    display: flex;
    width: 100vw;
    height: 95vh; /* Makes sure the row fills 80% of the screen height */
}

/* Second Row: Two Images Side by Side */
.second-row {
    display: flex;
    align-items: stretch; /* Ensures both images stretch equally */
    width: 100vw;
    height: 95vh;
}

.title-section p {
    color: white;
    margin-bottom: 1px;
    font-family: "Helveticish";
    font-size: 28px;

  }

  .babysitter-video {
    transform: translateX(-450px); /* Moves only the video */
    z-index: 1; /* Moves it behind other content but above the background */
  }

/* Image Section */
.image-section {
    width: 50vw; /* Ensures the image takes exactly half of the viewport width */
    height: 100%; /* Ensures it fully fills the row */
    display: flex;
}

/* Image Section */
.image-section-1 {
  width: 49.9vw; /* Ensures the image takes exactly half of the viewport width */
  height: 100%; /* Ensures it fully fills the row */
  display: flex;
  
}

/* Babysitter Image */
.babysitter-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.title-section {
    background-color: #32402f;
    font-family: "Rasputin Light";
    color: white;
    width: 50vw; /* Keeps the section half the width */
    padding: 10px 20px;
    text-align: center; /* Centers the text */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers vertically */
    align-items: center; /* Centers content horizontally */
    z-index: 2;
  }
  
  /* Ensures paragraphs, headings, and button are centered */
  .title-section h4,
  .title-section .book-now-button {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .title-section h2 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: clamp(30px, 6vw, 50px); /* Dynamically adjusts between 30px and 50px based on viewport width */
    font-weight: 100;
    max-width: 500px;

  }

  .book-now-button {
    background-color: #32402f; /* Matches background */
    color: white;
    border: 2px solid white;
    font-size: 18px;
    padding: 12px 10px;
    margin-top: 100px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 60%;
  }
  
  .book-now-button:hover {
    background-color: #263322; /* Darker shade on hover */
    transform: scale(1.05); /* Slight zoom effect */
  }

  /* Camera Feature Image Styling */
.camera-image {
  width: 700px; /* Adjust the width as needed */
}


/* ✅ Stacked Content Section */
.stacked-content {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centers items properly */
  text-align: center;
  gap: 0px; /* Controls spacing between elements */
}

/* ✅ Top Image (Camera) */
.top-image {
  max-width: 120px;
}

/* ✅ Adjust Title Spacing */
.stacked-title {
  font-family: "Rasputin Light";
  color: white;
  width: 100%;
  padding: 0;
  font-size: clamp(15px, 2vw, 35px); /* Dynamically adjusts between 30px and 50px based on viewport width */
  font-weight: bold;
  text-align: center;
  font-weight: 100;

}

/* ✅ Adjust Stacked Text */
.stacked-text {
  font-family: "Rasputin Light";
  font-size: clamp(10px, 2vw, 23px); /* Dynamically adjusts between 30px and 50px based on viewport width */
  max-width: 90%;
  line-height: 1.4;
  color: white;
  margin: 0px 0; /* Reduce space */
}

/* ✅ Bottom Image */
.bottom-image {
  width: clamp(450px, 90%, 800px); /* Dynamic width between 300px and 700px */
  object-fit: contain;
  margin: 0 0 0 0; /* Reduce spacing */
  margin-top: 20px;
  margin-bottom: 30px;
}
/* Responsive Design for Phones */
@media (max-width: 768px) {
    .first-row {
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .title-section {
        width: 100%; /* Allows full width on mobile */
        max-width: 90%;
        text-align: center;
        padding: 20px;
    }

    .image-section {
        width: 100%; /* Images take full width */
        height: auto;
    }
    .image-section-1 {
      width: 100%; /* Images take full width */
      height: auto;
  }

    .babysitter-image {
        border-radius: 10px;
    }

    .title-section p {
        font-size: 20px;
    
      }

      .book-now-button {
        margin-top: 50px;
      }
      .title-section h2 {
        font-size: 32px;
        line-height: 1.2; /* Ensure proper spacing between lines */
        word-wrap: break-word; /* Ensure long words break properly */
        max-width: 100%; /* Ensure it doesn't overflow */
        margin: 0 auto;
      }

       /* ✅ Flip the second row images */
       .second-row {
        display: flex !important; /* Ensure flexbox applies */
        flex-direction: column-reverse !important; /* Flip image order */
        width: 100%;
        height: auto;
      }

      .stacked-content {
        width: 100%;
    }

    .stacked-title {
        font-size: 28px;
    }

    .stacked-text {
        font-size: 16px;
        max-width: 80%;
    }

    .top-image {
        width: 100%;
        max-width: 120px;
        margin-top: 0px;
    }
    .bottom-image {
      width: 100%;
      max-width: 1000px;
      margin-bottom: 20px;
      height: auto;
  }
  .babysitter-video {
    transform: translateX(0px) !important; /* Reset position for mobile */
    z-index: 3; /* Forces it to be above the background */
    max-width: 100vw; /* Prevents it from being too large */
    height: 45vh; /* Maintains aspect ratio */
    object-fit: cover; /* Ensures it fills the container properly */
    display: block; /* Ensures it doesn't collapse */
}


}

/* ✅ Adjust for smaller screens */
@media (max-width: 1024px) {
  .bottom-image {
      max-width: 600px; /* Adjusts for medium screens */
  }
}
