.two-steps-container {
  background-color: #f3ede6;
  padding: 40px;
  text-align: center;
  justify-content: center;
}

.search-bar-two-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensures it spans the full width */
  margin: 40px 0; /* Adds some spacing */
  flex-direction: column; /* Stack items vertically */
}

.steps-title {
  color: black;
  font-family: "Helveticish";
  font-size: 35px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 200;
  padding: 0 20px; /* Adjusted for better responsiveness */
  max-width: 900px; /* Restricts width to force line break */
  margin-left: auto;
  margin-right: auto;
  word-wrap: break-word;
}

.steps {
  color: black;
  display: flex;
  justify-content: center;
  gap: 200px;
}

/* Individual step styling */
.step {
  font-weight: 20;
  max-width: 400px;
  text-align: center;
  padding: 0 20px; /* Adjusted for better responsiveness */
}

.step h3 {
  color: black;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 10px;
}

.step h2 {
  color: black;
  font-size: 50px;
  margin-bottom: 10px;
}

.step p {
  color: black;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 350;

}
.contact-info {
  font-size: 16px;  /* Smaller font size for the additional text */
  text-align: center;
  margin-top: 50px;
}

.contact-info a {
  color: black;
}



/* Responsive design for phone view */
@media (max-width: 768px) {
  .steps-title {
      font-size: 25px; /* Reduce font size for mobile */
      padding: 10px 20px;
      line-height: 1.4; /* Ensure proper spacing between lines */
      word-wrap: break-word; /* Ensure long words break properly */
      max-width: 100%; /* Ensure it doesn't overflow */
      margin: 0 auto;
  }

  .steps {
      flex-direction: column;
      align-items: center;
      gap: 20px;
  }

  .step {
      text-align: center;
      max-width: 90%;
  }

  .step h3 {
      font-size: 20px;
  }

  .step p {
      font-size: 16px;
  }
}