.tuttihelper-container {
    background-color: #f3ede6;
    text-align: center;
}

.tuttihelper-title-section h1 {
    font-size: clamp(25px, 4vw, 40px); /* Scales between 28px and 50px */
    line-height: 1.2;
    font-weight: 200;
}


.tuttihelper-video {
    z-index: 2; /* Moves it behind other content but above the background */
    object-fit: cover; /* Ensures it fills the container properly */
    width: 100%;
    height: 100%;
  }

.tuttihelper-title-section p {
    color: black;
    font-size: clamp(4px, 2.5vw, 32px); /* Scales between 28px and 50px */
    line-height: 1.6; /* Increases spacing between lines */
    font-family: "Helveticish";
    margin-top: 50px;
}


/* First Row: Title + Image (Image on the Right) */
.tuttihelper-first-row {
    display: flex;
    align-items: stretch; /* Ensures both sections match height */
    width: 100vw;
    height: 95vh;
}

/* Second Row: Image on the Left, Text on the Right */
.tuttihelper-second-row {
    display: flex;
    align-items: stretch; /* Ensures both sections stretch to equal height */
    width: 100vw;
    height: 95vh;
}

.line-divider {
    width: 100%; /* Makes the line span the full width */
    height: 4px; /* Adjust thickness */
    background-color: black; /* Black color */
    border: none; /* Removes default border */
    margin: 0px 0; /* Adds spacing above and below */
}

.vertical-divider {
    width: 4px; /* Adjust thickness */
    height: 100%; /* Makes it fill the row */
    background-color: black; /* Black color */
    margin: 0 0px; /* Adds spacing between sections */
    position: relative; /* Ensures correct stacking */
    z-index: 3;
}

/* Title Section (Both Rows) */
.tuttihelper-title-section {
    font-family: "Rasputin Light";
    font-weight: 200;
    color: black;
    width: 50vw;
    padding: 20px 40px;
    text-align: center;
    flex-direction: column;
    justify-content: space-between; /* Ensures text expands */
    height: 100%; /* Matches image height */
    padding-top: 40px; /* Keeps space at the top */

}


/* Image Section (Both Rows) */
.tuttihelper-image-section {
    width: 50vw;
    height: 100%;
    display: flex;
}

.tuttihelper-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

/* Responsive Design for Phones */
@media (max-width: 768px) {
    .tuttihelper-first-row, .tuttihelper-second-row {
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .tuttihelper-title-section {
        width: 100%;
        max-width: 90%;
        text-align: center;
        padding: 20px;
    }

    .tuttihelper-image-section {
        width: 100%;
        height: auto;
        padding: 10px 0;
    }

    .tuttihelper-image {
        border-radius: 10px;
        margin: 10px 0;
    }
}

.tutti-text {
    font-family: "Helveticish";
}

.tutti-text-bald {
    font-weight: bold;
}

.helper-book-now-button {
    color: black;
    border: 2px solid black;
    font-size: 18px;
    padding: 12px 10px;
    margin-top: 80px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 60%;
}

.helper-book-now-button:hover {
    background-color: #e0d6c8; /* Darker shade */
    transform: scale(1.05); /* Slight zoom effect */
}



/* Responsive Design for Phones */
@media (max-width: 768px) {
    .tuttihelper-first-row {
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .tuttihelper-title-section {
        width: 100%; /* Allows full width on mobile */
        max-width: 90%;
        text-align: center;
        padding: 20px;
    }

    .tuttihelper-image-section {
        width: 100%; /* Images take full width */
        height: auto;
        padding: 10px 0;
    }

    .tuttihelper-image {
        border-radius: 10px;
        margin: 10px 0;
    }
    .tuttihelper-title-section p {
        color: black;
        font-size: 20px;
        margin-bottom: 10px;
        line-height: 1.6; /* Increases spacing between lines */
        margin-top: 0px;
    
    }
    .tuttihelper-second-row {
        display: flex !important; /* Ensure flexbox applies */
        flex-direction: column-reverse !important; /* Flip image order */
        width: 100%;
        height: auto;
      }
      .tuttihelper-title-section h1 {
        font-size: 30px; /* Adjusts font for smaller screens */
    }
    .line-divider {
        display: none;
    }
    .vertical-divider {
        display: none; 
    }
    .tuttihelper-video {
        max-width: 100vw; /* Prevents it from being too large */
        height: 45vh; /* Maintains aspect ratio */
        object-fit: cover; /* Ensures it fills the container properly */
     }
}

/* Additional Fix for Mobile View */
@media (max-width: 768px) {
    .tuttihelper-second-row {
        padding: 30px 0; /* Ensures space between sections */
    }

    .tuttihelper-title-section h1 {
        font-size: 30px; /* Adjusts font for smaller screens */
    }
    .tuttihelper-second-row {
        padding: 30px 0; /* Ensures space between sections */
    }
}
