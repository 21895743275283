.video {
  position: relative;
  width: 100vw;
  height: 80vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tuttihelper-video-1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay-text {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  font-size: 2.6rem;
  font-weight: bold;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  padding: 0 1rem;
  font-family: "Helveticish";
  text-transform: uppercase;
  line-height: 1.3; /* adds spacing between lines */
}

/* Responsive design for mobile view */
@media (max-width: 768px) {
  .video {
    height: 50vh;
  }

  .tuttihelper-video-1 {
    width: 100%;
    height: 100%;
  }

  .video-overlay-text {
    font-size: 1.3rem;
    bottom: 10px;
  }
}
